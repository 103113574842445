import React from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface VideoProps {
  src: string;
  width?: string;
  height?: number;
  margin?: string;
  pc_width?: string;
  pc_height?: number;
  pc_margin?: string;
  poster?: string;
  mute?: boolean;
}

const Video: React.FC<VideoProps> = (props) => {
  return (
    <StVideo
      src={props.src}
      width={props.width}
      height={props.height}
      margin={props.margin}
      poster={props.poster}
      controls
      pc_width={props.pc_width}
      pc_height={props.pc_height}
      pc_margin={props.pc_margin}
      muted={props.mute}
    />
  );
};

const StVideo = styled.video<VideoProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  margin: ${(props) => props.margin};
  @media ${device.pc} {
    width: ${(props) => props.pc_width};
    height: ${(props) => props.pc_height}px;
    margin: ${(props) => props.pc_margin};
  }
`;

export default Video;
