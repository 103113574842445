import React from "react";
import { device } from "../hooks/device";

// img
import Logo from "../img/Logo.svg";
import edint from "../img/edint.svg";
import exhibition from "../img/exhibition.png";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import { useNavigate } from "react-router-dom";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import TextLine from "../atomic/atom/TextLine";
import Request from "../pages/ConsultRequest/index";

const Home6 = ({ qna, setMyPageScroll }) => {
  const country = useRecoilValue(languageIs);
  const navigate = useNavigate();

  return (
    <StHomePage ref={qna}>
      <div className="column">
        <div className="flex">
          <img className="logo" src={Logo} alt="" />
          <div className="h6">
            {" "}
            <TextTwoPart
              textKor="서비스를 더 자세히 알고 싶어요!"
              textEng="Contact Us"
            />
          </div>
        </div>
        <div className="main">
          <DivTwoPartImprove
            pc_display="flex"
            display="grid"
            lineheight={41}
            pc_lineheight={80}
            text_align="center"
          >
            <TextLine text="궁금하신 점이&nbsp;" />
            <TextLine text="있으면 무엇이든" />
          </DivTwoPartImprove>
          <TextTwoPart textKor="" textEng="you may have!" />
        </div>
        <div className="blue">
          <TextLine
            text="삼성전자 12년 경력의 전문가, 뛰어난 역량의 팀원들이 합심하여 열심히
          개발하고 있어요🙂"
            lineheight={21}
            pc_lineheight={47}
          />
        </div>
        {/* <div className="content">
          <div className="upBox">
            <div className="first">
              <TextLine text="프록토매틱 서비스 관련" />
              <TextTwoPart
                textKor="문의는 아래를 이용해주세요"
                textEng="Proctormatic, please click below."
                pc_margin="0 auto"
              />
            </div>
            <div className="second">
              <div
                className="customerCenter"
                onClick={() => navigate(`/request`)}
              >
                <TextTwoPart textKor="도입 문의" textEng="Customer Service" />
              </div>
              <div
                className="emailCustomer"
                onClick={() => {
                  navigate(`/myPage`);
                  setMyPageScroll("customerCenter");
                }}
              >
                <TextTwoPart textKor="고객센터" textEng="Customer Service" />
              </div>
            </div>
          </div>
          <img className="edint" src={exhibition} alt="" />
        </div> */}
        <Request />
        {/* Noah */}
        <div className="infoBox">
          <div className="infoLine">
            <span className="infoTitle">전화 상담</span>
            <span className="infoText">070-8065-0811</span>
          </div>
          <div className="infoLine">
            <span className="infoTitle">이메일 문의</span>
            <a
              href={"mailto:hiya@proctormatic.com"}
            >
              hiya@proctormatic.com
            </a>
          </div>
          <div className="infoLine">
            <span className="infoTitle">운영 시간 안내</span>
            <div className="infoText">
              <span className="infoText">평일 (월 ~ 금) 10:00 ~ 17:00</span>
              <div
                style={{
                  color: "#55595F",
                  fontSize: "14px",
                  fontFamily: "regular",
                  lineHeight: "21px",
                }}
              >
                * 점심시간 12:00 ~ 13:00 / 토, 일, 공휴일 휴무
              </div>
            </div>
          </div>
        </div>
      </div>
    </StHomePage>
  );
};

const StHomePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0 auto 0 auto;
  box-sizing: border-box;
  @media ${device.pc} {
    width: 1440px;
    padding: 140px 124px;
    .mobile {
      display: none;
    }
    .upBox {
      width: 50%;
    }
    .second {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 22px;
      justify-content: center;
      .emailCustomer,
      .customerCenter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border-radius: 43px;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
        cursor: pointer;
      }
      .customerCenter {
        color: white;
        background-color: #20315b;
        cursor: pointer;
      }
      .emailCustomer {
        border: 1px solid #222222;
        text-decoration: none;
        color: black;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      margin-right: auto;
    }
    .logo {
      width: 28px;
      height: 28px;
      margin: 0 6px 0 0;
    }
    .column {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px solid #bdbdbd;
      margin-top: 90px;
    }
    .h6 {
      font-size: 26px;
      font-family: "Medium";
    }
    .main {
      margin: 50px auto 0 0;
      font-family: "Bold";
      font-size: 54px;
      line-height: 80px;
    }
    .blue {
      margin: 14px auto 0 0;
      color: #2276dc;
      font-family: "Medium";
      font-size: 32px;
    }
    .edint {
      width: 50%;
      margin-top: auto;
    }
    .first {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 22px;
      font-family: "Regular";
      line-height: 33px;
      width: 100%;
      margin-bottom: 24px;
    }
    .email {
      width: 300px;
      font-size: 26px;
      color: #2276dc;
      text-decoration: underline;
      margin: 0 auto;
      cursor: pointer;
    }
    .infoBox {
      display: flex;
      flex-direction: column;
      width: 800px;
      padding: 60px;
      box-sizing: border-box;
      gap: 16px;
      border-radius: 24px;
      background: #FAFAFA;
      .infoLine {
        display: flex;
        font-size: 16px;
        line-height: 24px;
        gap: 24px;
        .infoTitle {
          font-family: "bold";  
        }
        .infoText {
          display: flex;
          flex-direction: column;
          gap: 9.5px;
          font-family: "regular";
        }
      }
    }
  }
  @media ${device.tabMob} {
    width: 100%;
    padding: 100px 24px;
    max-width: 731px;
    .upBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* height : 198px; */
      padding: 32px;
      box-sizing: border-box;
      .second {
        display: grid;
        flex-direction: column;
        gap: 10px;
        font-size: 13px;
        font-family: "medium";
        line-height: 16px;
        .customerCenter {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 135px;
          height: 39px;
          border-radius: 43px;
          background-color: #20315b;
          color: white;
          cursor: pointer;
        }
        .emailCustomer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 135px;
          height: 39px;
          border: 1px solid #222222;
          border-radius: 43px;
          color: black;
          text-decoration: none;
        }
      }
    }
    .flex {
      display: flex;
      align-items: center;
      margin-right: auto;
    }
    .logo {
      width: 28px;
      height: 28px;
      margin: 0 6px 0 0;
    }
    .column {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: 1px solid #bdbdbd;
      border-bottom: none;
      margin-top: 50px;
    }
    .h6 {
      font-size: 19px;
      font-family: "Medium";
    }
    .main {
      margin-top: 50px;
      text-align: center;
      font-family: "Bold";
      font-size: 28px;
    }
    .blue {
      width: 260px;
      margin-top: 14px;
      text-align: center;
      color: #2276dc;
      font-family: "Bold";
      font-size: 14px;
      word-break: break-all;
    }
    .edint {
      width: 100%;
      margin-top: auto;
    }
    .first {
      text-align: center;
      font-size: 15px;
      line-height: 22px;
      width: 100%;
      font-size: "regular";
      margin-bottom: 14px;
    }
    .infoBox {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      max-width: 800px;
      padding: 24px;
      gap: 24px;
      border-radius: 24px;
      background: #FAFAFA;
      margin-top: 50px;
      .infoLine {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        line-height: 24px;
        gap: 10px;
        .infoTitle {
          font-family: "bold";  
        }
        .infoText {
          display: flex;
          flex-direction: column;
          gap: 9.5px;
          font-family: "regular";
        }
      }
    }
  }
`;

export default Home6;
