import React from "react";
import styled from "styled-components";

// styled components

// img
import Logo from "../img/Logo.svg";
import awardTitle from "../img/awardTitle.svg";
import ces from "../img/ces.svg";
import samsung from "../img/samsung.png";
import { device } from "../hooks/device";
import underline from "../img/underline.png";
import jigongu from "../img/jigongu.svg";
import yellowUnder from "../img/yellowUnder.png";
import AI1 from "../img/AI1.png";
import AI2 from "../img/AI2.png";
import AI3 from "../img/AI3.png";
import qurious from "../img/qurious.svg";
import awardTitleEng from "../img/quriousEng.png";
import yellowLine from "../img/yellowLine.svg";
import yellowLineTwo from "../img/yellowLineTwo.svg";
import yellowLineThree from "../img/yellowLineThree.svg";
import yellowLineOneEng from "../img/yellowLineOneEng.png";
import yellowLineTwoEng from "../img/yellowLineTwoEng.png";
import home4Video1 from "../img/Landing_Home4_Video1.mp4";
import home4Video2 from "../img/Landing_Home4_Video2.mp4";
import home4Video3 from "../img/Landing_Home4_Video3.mp4";
import home4Thumbnail1 from "../img/Home4_thumbnail1.png";
import home4Thumbnail2 from "../img/Home4_thumbnail2.png";
import home4Thumbnail3 from "../img/Home4_thumbnail3.png";
import ifAward from "../img/LandingIFAward.png";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import TextLine from "../atomic/atom/TextLine";
import Img from "../atomic/atom/Img";
import AnimatedCounter from "./AnimatedCounter";

const Home4 = ({ technic }) => {
  const language = useRecoilValue(languageIs);

  return (
    <StPage underline={underline} ref={technic} language={language}>
      <div className="inBox">
        <div className="column">
          <div className="line">
            <img className="logo" alt="" src={Logo} />
            <div className="h6">
              <TextTwoPart
                // textKor="부정행위 AI 자동검증 솔루션"
                textKor="AI 컴퓨터 비전 분석 엔진"
                textEng="Why Proctormatic?"
              />
            </div>
          </div>
          <div className="column">
            <div className="ft35">
              <DivTwoPartImprove
                pc_display="flex"
                display="grid"
                justify="center"
                pc_justify="start"
              >
                <TextTwoPart
                  // textKor="꼼꼼하고 공정한 분석,&nbsp;"
                  textKor="꼼꼼하고 공정한 AI 감독관"
                  textEng="Introducing our smart&nbsp;"
                  tab_margin="0 auto"
                />
                {/* <TextLine text="‘지공거’ AI 엔진" /> */}
              </DivTwoPartImprove>
              {/* 꼼꼼하고 공정한 분석, */}
              {/* <br className="mobile" /> ‘지공거’ AI 엔진 */}
            </div>
            <div className="subtitle">
              <DivTwoPartImprove
                pc_display="flex"
                display="grid"
                justify="center"
                pc_justify="start"
              >
                <TextTwoPart
                  textKor="부정행위를 자동 포착하여 요약된&nbsp;"
                  textEng="It automatically detects cheating and creates&nbsp;"
                />
                <TextTwoPart
                  textKor="결과보고서로 자동 생성!"
                  textEng="summarized reports."
                  tab_margin="0 auto"
                />
              </DivTwoPartImprove>
              {/* 부정행위를 자동 포착하여 요약된 */}
              {/* <br className="mobile" /> 결과보고서로 자동 생성! */}
            </div>
          </div>
          <div className="column">
            <div className="header">
              <TextLine
                text="AI 분석 기술과 감지 항목"
              />
            </div>
            <div className="imgs">
              {/* 국문 사이트에도 AutoPlay 추가 Chole 요청 구두 전달 */}
              <video
                className="upImg"
                muted={true}
                autoPlay
                loop
                poster={home4Thumbnail1}
                controls={true}
                src={home4Video1}
              />
              <div className="imgRight">
                <div className="rightTitle">
                  <TextLine
                    text="행동패턴 분석"
                  />
                </div>
                <div className="rightText">
                  <TextLine
                    text="응시 중 비정상적인 손과 팔, 얼굴의 움직임, 시선 방향 등을"
                  />
                  <br/>
                  <TextLine
                    text="감지해서 꼼꼼히 기록해요."
                  />
                </div>
                <div className="rightTag">
                  <TextLine
                    text="#Pose Estimation"
                  />
                  <TextLine
                    text="#HeadPose"
                  />
                </div>
              </div>
              <video
                className="upImg"
                muted={true}
                autoPlay
                loop
                poster={home4Thumbnail2}
                controls={true}
                src={home4Video2}
              />
              <div className="imgRight">
                <div className="rightTitle">
                  <TextLine
                    text="주변 사물/소리 분석"
                  />
                </div>
                <div className="rightText">
                  <TextLine
                    text="응시 중 사용이 금지된 또는 제한된 물건과 비정상적인 소음"
                  />
                  <br/>
                  <TextLine
                    text="크기를 감지해서 기록해요."
                  />
                </div>
                <div className="rightTag">
                  <TextLine
                    text="#Object Detection"
                  />
                  <TextLine
                    text="#Sound Analysis"
                  />
                </div>
              </div>
              <video
                className="upImg"
                muted={true}
                autoPlay
                loop
                poster={home4Thumbnail3}
                controls={true}
                src={home4Video3}
              />
              <div className="imgRight">
                <div className="rightTitle">
                  <TextLine
                    text="결과보고서 자동 생성"
                  />
                </div>
                <div className="rightText">
                  <TextLine
                    text="감지된 모든 내역을 이상행동, 부정행위로 분류하여 일목요"
                  />
                  <br/>
                  <TextLine
                    text="연한 보고서 형태로 제공해요."
                  />
                </div>
                <div className="rightTag">
                  <TextLine
                    text="#Deep Classification"
                  />
                  <TextLine
                    text="#Auto-Reporting"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="countingBox">
            <div className="countingElement">
              <div className="countingText" style={{flexBasis: 267}}>
                <div className="title">도입 고객사</div>
                <div className="number">
                  <AnimatedCounter 
                    from={0}
                    to={15}
                  />
                  <span className="text">개</span>
                </div>
              </div>
              <div className="countingText" style={{flexBasis: 267}}>
                <div className="title">감독 시행 수</div>
                <div className="number">
                  <AnimatedCounter 
                    from={0}
                    to={166}
                  />
                  <span className="text">개+</span>
                </div>
              </div>
              <div className="countingText" style={{flexBasis: 267}}>
                <div className="title">분석된 시험 영상</div>
                <div className="number">
                  <AnimatedCounter 
                    from={0}
                    to={1036}
                  />
                  <span className="text">개+</span>
                </div>
              </div>
              <div className="countingText" style={{flexBasis: 300}}>
                <div className="title">절약된 운영비</div>
                <div className="number">
                  <AnimatedCounter 
                    from={0}
                    to={8300}
                    comma={true}
                  />
                  <span className="text">만원+</span>
                </div>
              </div>
            </div>
          </div>
          <div className="awardBox">
            <img className="awardTitle" alt="" src={awardTitle} />
            <DivTwoPartImprove display="grid" pc_display="none">
              <Img src={qurious} alt="" margin="0 auto 5.5px" />
              <DivTwoPartImprove
                display="grid"
                position="relative"
                text_align="center"
              >
                <TextLine
                  text="믿을 만한 서비스인지"
                  // margin="0 auto"
                  fontsize={24}
                  fontFamily="bold"
                  lineheight={36}
                />
                <Img
                  className="yellowLineOne"
                  src={yellowLineTwo}
                  alt=""
                  position="absolute"
                  bottom="0px"
                  right="50%"
                  transform="translate(50%, 0);"
                  // margin="auto auto 0"
                />
              </DivTwoPartImprove>
              <DivTwoPartImprove
                display="grid"
                position="relative"
                text_align="center"
              >
                <TextLine
                  text="걱정되세요?"
                  fontsize={24}
                  fontFamily="bold"
                  lineheight={36}
                  // textAlign="center"
                />
                <Img
                  src={yellowLine}
                  alt=""
                  position="absolute"
                  bottom="0px"
                  right="50%"
                  transform="translate(50%, 0);"
                  // margin="auto auto 0"
                />
              </DivTwoPartImprove>
            </DivTwoPartImprove>
            <div className="awardImages">
              <img
                className="samsungImage"
                onClick={() => {
                  window.open("https://samsungclab.com/");
                }}
                alt=""
                src={samsung}
              />
              <img
                className="cesImage"
                onClick={() => {
                  window.open(
                    "https://www.ces.tech/Innovation-Awards/Honorees/2023/Honorees/P/Proctormatic.aspx"
                  );
                }}
                alt=""
                src={ces}
              />
              <img
                className="ifImage"
                onClick={() => {
                  window.open(
                    "https://ifdesign.com/en/winner-ranking/project/proctormatic/635656"
                  );
                }}
                alt=""
                src={ifAward}
              />
            </div>
            <div className="awardDown">
              <span
                className="ces"
                onClick={() => {
                  window.open("https://samsungclab.com/");
                }}
              >
                삼성전자 사내벤처 C-Lab
              </span>{" "}
              출신 전문가들이 개발하여 전 세계의 혁신적인 제품에게
              수여하는
              &nbsp;
              <span
                onClick={() => {
                  window.open(
                    "https://www.ces.tech/Innovation-Awards/Honorees/2023/Honorees/P/Proctormatic.aspx"
                  ); 
                }}
                className="ces"
              >
                CES 2023 Innovation Awards
              </span>
              를 수상하여 신뢰성과 기술력을 입증했고, 세계 3대 디자인 어워드인
              <br className="pc"/>
              <span className="mobile">&nbsp;</span>
              <span
                onClick={() => {
                  window.open(
                    "https://ifdesign.com/en/winner-ranking/project/proctormatic/635656"
                  ); 
                }}
                className="ces"
              >
                2024 iF Design Award
              </span>
              에도 수상하며 사용자 편의성을 인정받았어요. 안심하고 이용하세요 😊
              {/* <div className="awardRight">
                <img
                  className="samsungImage"
                  onClick={() => {
                    window.open("https://samsungclab.com/");
                  }}
                  alt=""
                  src={samsung}
                />
                <img
                  className="cesImage"
                  onClick={() => {
                    window.open(
                      "https://www.ces.tech/Innovation-Awards/Honorees/2023/Honorees/P/Proctormatic.aspx"
                    );
                  }}
                  alt=""
                  src={ces}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </StPage>
  );
};

const StPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  @media ${device.tabMob} {
    width: 100%;
    padding: 100px 24px;
    .awardTitleMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 114px;
      .start {
        width: 36px;
        height: 36px;
        margin-bottom: 5px;
      }
      .wording {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        transform: translate(0%, 0);
        text-align: center;
        z-index: 10;
        font-size: 24px;
        font-family: "Bold";
        line-height: 36px;
      }
      .middle {
        position: relative;
        width: 100%;
        height: 36px;
      }
      .end {
        position: relative;
        width: 100%;
        height: 36px;
      }
      .yellowLineOne {
        position: absolute;
        bottom: 0;
        width: 234px;
        height: 12px;
        left: 0;
        transform: translate(0%, 0);
        z-index: 5;
      }
      .yellowLineTwo {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 5;
      }
    }
    .awardImages {
      display: grid;
      /* grid-template-columns: 1fr 1fr; */
      /* grid-template-rows: auto auto; */
      row-gap: 28.05px;
      column-gap: 28px;
      justify-content: center;
      align-items: center;
      margin-top: 48px;
    }
    .cesImage {
      width: 92px;
      cursor: pointer;
      grid-column: 2 / 3;
    }
    .samsungImage {
      width: 111px;
      height: 32.8px;
      /* min-width: 120px; */
      cursor: pointer;
      grid-column: 1 / 2;
    }
    .ifImage {
      width: 232px;
      height: 125px;
      cursor: pointer;
      grid-column: 1 / 3;
    }
    .notM {
      display: none;
    }
    .tablet {
      display: none;
    }
    .tag {
      color: #2276dc;
      font-size: 12px;
      margin-top: 22px;
    }
    .goryeo {
      font-size: 15px;
      font-family: "Regular";
      color: #909090;
      margin-top: 7px;
    }
    .right {
      display: flex;
      flex-direction: column;
      margin: 64px auto;
    }
    .words {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .word {
      position: relative;
      font-family: "Medium";
      font-size: 22px;
      z-index: 11;
    }
    .yellowUnder {
      position: absolute;
      right: 50%;
      transform: translate(50%, 0);
      bottom: 0;
      z-index: 10;
      width: 95px;
    }
    .hi {
      font-family: "medium";
      font-size: 16px;
      line-height: 24px;
      position: relative;
      z-index: 20;
    }
    .imgs {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 12px;
      gap: 36px;
      .imgRight {
        display: flex;
        flex-direction: column;
        gap: 14px;
        text-align: center;
        padding: 24px 24px 26px 24px;
        .rightTitle {
          font-family: "bold";
          font-size: 24px;
          line-height: 36px;
        }
        .rightText {
          font-family: "regular";
          font-size: 15px;
          line-height: 22px;
        }
        .rightTag {
          display: flex;
          justify-content: center;
          font-family: "regular";
          font-size: 14px;
          line-height: 18px;
          color: #276DDC;
          margin-top: 8px;
          gap: 14px;
        }
      }
    }
    .jigongu {
      width: 152px;
      height: 160px;
      min-width: 152px;
      min-height: 160px;
      /* margin-top : 16px; */
      margin: 24px auto 0;
    }
    .inBox {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .pc {
      display: none;
    }
    /* Noah, 랜딩페이지 개선 */
    .countingBox {
      width: 100%;
      margin: 138px 0px 38px 0;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background-color: #F7F9FF;
        top: -38px;
        width: 100%;
        height: 310px;
        z-index: -1;
      }
      .countingElement {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
      .countingText {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 12px;
        .title {
          font-family: "medium";
          font-size: 15px;
          line-height: 20px;
        }
        .number {
          font-family: "bold";
          font-size: 32px;
          line-height: 70px;
          color: #2276dc;
          .text {
            font-size: 20px;
          }
        }
      }
    }
    .awardRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 48px;
      width: 234px;
      /* padding: 0 16.47px; */
      box-sizing: border-box;
      /* height : 130px; */
    }
    .awardDown {
      align-items: center;
      width: 100%;
      margin-top: 48px;
      text-align: center;
      font-size: 16px;
      font-family: "medium";
      line-height: 24px;
      .ces {
        color: #2276dc;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .awardTitle {
      display: none;
    }
    .awardTitleMobile {
      width: 300px;
      margin: 0 auto;
    }
    .awardBox {
      width: 100%;
      padding: 38px 24px;
      box-sizing: border-box;
      border: 1px solid #bbc5d5;
      border-radius: 24px;
      margin-top: 100px;
      display: flex;
      flex-direction: column;
    }
    .header {
      font-size: 16px;
      font-family: "Bold";
      line-height: 24px;
    }
    .downBox {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .text {
      font-size: 15px;
      line-height: 22px;
      margin-top: 14px;
      text-align: center;
    }
    .downBoxHead {
      font-size: 24px;
      font-family: "Bold";
      line-height: 36px;
    }
    .downImg {
      width: 100%;
    }
    .upImg {
      width: 100%;
      background-color: black;
    }
    .subtitle {
      color: #2276dc;
      font-size: 14px;
      font-family: "Bold";
      line-height: 21px;
      text-align: center;
      margin: 14px 0 50px;
    }
    .logo {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
    .line {
      display: flex;
      align-items: center;
    }
    .column {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .h6 {
      font-size: 19px;
      font-family: "Medium";
    }
    .ft35 {
      font-size: 28px;
      font-family: "Bold";
      line-height: 41px;
      margin-top: 50px;
      text-align: center;
    }
    .boxs {
      display: grid;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      margin-top: 12px;
    }
    .box {
      width: 100%;
      background: #f2f3f3;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media ${device.pc} {
    width: 1440px;
    padding: 140px 124px;
    .onlyForMobile {
      display: none !important;
    }
    .inbox {
      width: 100%;
    }
    .cesImage {
      width: 157.4px;
      height: 219px;
      cursor: pointer;
    }
    .samsungImage {
      width: 190px;
      height: 56.1px;
      cursor: pointer;
    }
    .ifImage {
      margin-left: 12px;
      width: 392px;
      height: 212px;
      cursor: pointer;
    }
    .tablet {
      display: none;
    }
    .tag {
      color: #2276dc;
      font-size: 17px;
      margin-top: 22px;
    }
    .goryeo {
      font-size: 17px;
      line-height: 25px;
      font-family: "Regular";
      color: #909090;
      margin-top: 4px;
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
    }
    /* Noah, 랜딩페이지 개선 */
    .imgRight {
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      justify-content: center;
      gap: 32px;
      padding: 40px 24px;
      .rightTitle {
        font-family: "bold";
        font-size: 36px;
        line-height: 47px;
      }
      .rightText {
        font-family: "regular";
        font-size: 22px;
        line-height: 33px;
      }
      .rightTag {
        display: flex;
        gap: 14px;
        font-size: 20px;
        font-family: "regular";
        line-height: 24px;
        color: #2276dc;
      }
    }
    .words {
      width: 302px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .word {
      position: relative;
      display: flex;
      font-family: "Medium";
      font-size: 22px;
      line-height: 33px;
    }
    .hi {
      width: auto;
      z-index: 10;
    }
    .yellowUnder {
      position: absolute;
      left: 0;
      transform: translate(-10px, 0);
      bottom: 0;
      z-index: 8;
    }
    .imgs {
      display: grid;
      /* grid-template-columns: auto auto; */
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      row-gap: 120px;
      column-gap: 30px;
      margin-top: 24px;
    }
    .jigongu {
      width: 302px;
      height: 317px;
      margin-top: 16px;
    }
    .mobile {
      display: none;
    }
    /* Noah, 랜딩페이지 개선 */
    .countingBox {
      width: 100%;
      min-width: 1192px;
      margin-top: 264px;
      margin-bottom: 140px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background-color: #F7F9FF;
        top: -124px;
        width: 100vw;
        min-width: 1440px;
        height: 444px;
        z-index: -1;
      }
      .countingElement {
        width: 100%;
        display: flex;
        gap: 30px;
        align-items: center;
        .countingText {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: center;
          gap: 20px;
          .title {
            font-family: "medium";
            font-size: 32px;
            line-height: 80px;
          }
          .number {
            font-family: "bold";
            font-size: 70px;
            line-height: 80px;
            color: #2276dc;
            .text {
              font-size: 40px;
            }
          }
        }
      }
    }
    .awardRight {
      width: 395px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: auto;
    }
    .awardDown {
      /* display: flex; */
      /* align-items: center; */
      width: 100%;
      /* margin-top: 60px; */
      font-family: "medium";
      font-size: 24px;
      font-weight: 500;
      line-height: 39px;
      .ces {
        color: #2276dc;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .awardTitle {
      margin: 0 auto;
    }
    .awardTitleMobile {
      display: none;
    }
    .awardBox {
      width: 100%;
      /* height: 538px; */
      padding: 72px 110px;
      box-sizing: border-box;
      border: 1px solid #bbc5d5;
      border-radius: 36px;
      margin-top: 140px;
      display: flex;
      flex-direction: column;
      gap: 60px;
    }
    .awardImages {
      display: flex;
      gap: 48px;
      justify-content: center;
      align-items: center;
    }
    .header {
      font-size: 24px;
      font-family: "Bold";
    }
    .downBox {
      width: 100%;
      padding: ${(props) =>
        props.language === "/ko" ? "40px 24px" : "40px 16px"};
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .text {
      width: 100%;
      font-size: 22px;
      line-height: 33px;
      margin-top: 32px;
      text-align: center;
      word-break: keep-all;
    }
    .downBoxHead {
      font-size: 32px;
      font-family: "Bold";
    }
    .downImg {
      width: 380px;
      height: 213.59px;
    }
    .upImg {
      /* width: 786px; */
      width: 600px;
      /* height: 442px; */
      height: 440px;
      /* margin-bottom: 140px; */
      /* background-color: black; */
    }
    .subtitle {
      color: #2276dc;
      font-size: 32px;
      font-family: "Medium";
      line-height: 47px;
      margin: 16px 0 90px;
    }
    .logo {
      width: 33px;
      height: 38px;
      margin-right: 13px;
    }
    .line {
      display: flex;
      align-items: center;
    }
    .column {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .h6 {
      font-size: 26px;
      font-family: "Medium";
    }
    .ft35 {
      font-size: 54px;
      font-family: "Bold";
      line-height: 80px;
      margin-top: 90px;
    }
    .boxs {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 24px auto 0 auto;
    }
    .box {
      width: 380px;
      /* height: 496px; */
      background: #f2f3f3;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export default Home4;
