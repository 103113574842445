import React, { Children, ReactNode } from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface FlexDivProp {
  divRef?: React.MutableRefObject<any>;
  children?: ReactNode;
  onclick?: () => void;
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter?: (e: React.DragEvent<HTMLDivElement>) => void;
  font_color?: string;
  pc_font_family?: string;
  pc_font_size?: number;
  background_color?: string;
  z_index?: number;
  position?: string;
  top?: number;
  left?: number;
  right?: number;
  width?: string;
  height?: number;
  pc_width?: string;
  pc_height?: number;
  pc_padding?: string;
  pc_display?: string;
  pc_grid_template_column?: string;
  grid_template_column?: string;
  grid_template_rows?: string;
  pc_justify?: string;
  pc_align_items?: string;
  pc_lineheight?: number;
  pc_margin?: string;
  pc_gap?: number;
  pc_border?: string;
  border?: string;
  border_top?: string;
  border_bottom?: string;
  border_left?: string;
  border_right?: string;
  border_radius?: number;
  pc_text_align?: string;
  display?: string;
  justify?: string;
  align_items?: string;
  padding?: string;
  font_size?: number;
  font_family?: string;
  lineheight?: number;
  margin?: string;
  gap?: number;
  text_align?: string;
  box_shadow?: string;
  pc_box_shadow?: string;
  pc_border_radius?: number;
  overflowX?: string;
  overflow?: string;
  scroll_width?: string;
  scroll_hide?: boolean;
  cursor?: string;
  flex?: string;
  pc_flex?: string;
  font_weight?: number;
  pc_font_weight?: number;
  font_style?: string;
  pc_font_style?: string;
  white_space?: string;
  text_overflow?: string;
  word_break?: string;
  pc_flex_direction?: string;
  pc_max_width?: number;
  pc_box_sizing?: string;
  pc_background_color?: string;
  pc_min_width?: string;
  pc_hover_background_color?: string;
  flex_direction?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  pc_transform?: string;
}

const DivTwoPartImprove: React.FC<FlexDivProp> = (props) => {
  return (
    <StDiv
      ref={props.divRef}
      onClick={props.onclick}
      onDrop={props.onDrop}
      onDragEnter={props.onDragEnter}
      onDragLeave={props.onDragLeave}
      onDragOver={props.onDragOver}
      width={props.width}
      height={props.height}
      pc_width={props.pc_width}
      pc_height={props.pc_height}
      pc_font_family={props.pc_font_family}
      font_color={props.font_color}
      pc_font_size={props.pc_font_size}
      position={props.position}
      z_index={props.z_index}
      top={props.top}
      left={props.left}
      right={props.right}
      pc_border={props.pc_border}
      pc_border_radius={props.pc_border_radius}
      border={props.border}
      border_radius={props.border_radius}
      border_bottom={props.border_bottom}
      border_top={props.border_top}
      border_left={props.border_left}
      border_right={props.border_right}
      box_shadow={props.box_shadow}
      pc_padding={props.pc_padding}
      padding={props.padding}
      pc_display={props.pc_display}
      pc_grid_template_column={props.pc_grid_template_column}
      grid_template_column={props.grid_template_column}
      grid_template_rows={props.grid_template_rows}
      pc_align_items={props.pc_align_items}
      background_color={props.background_color}
      pc_lineheight={props.pc_lineheight}
      pc_margin={props.pc_margin}
      pc_gap={props.pc_gap}
      pc_text_align={props.pc_text_align}
      pc_justify={props.pc_justify}
      display={props.display}
      align_items={props.align_items}
      font_size={props.font_size}
      font_family={props.font_family}
      lineheight={props.lineheight}
      margin={props.margin}
      gap={props.gap}
      pc_box_shadow={props.pc_box_shadow}
      scroll_width={props.scroll_width}
      scroll_hide={props.scroll_hide}
      text_align={props.text_align}
      justify={props.justify}
      overflow={props.overflow}
      overflowX={props.overflowX}
      cursor={props.cursor}
      flex={props.flex}
      pc_flex={props.pc_flex}
      font_weight={props.font_weight}
      pc_font_weight={props.pc_font_weight}
      font_style={props.font_style}
      pc_font_style={props.pc_font_style}
      white_space={props.white_space}
      text_overflow={props.text_overflow}
      word_break={props.word_break}
      pc_flex_direction={props.pc_flex_direction}
      pc_max_width={props.pc_max_width}
      pc_box_sizing={props.pc_box_sizing}
      pc_background_color={props.pc_background_color}
      pc_min_width={props.pc_min_width}
      pc_hover_background_color={props.pc_hover_background_color}
      flex_direction={props.flex_direction}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      pc_transform={props.pc_transform}
    >
      {props.children}
    </StDiv>
  );
};

const StDiv = styled.div<FlexDivProp>`
  font-family: ${(props) => props.font_family};
  color: ${(props) => props.font_color};
  font-size: ${(props) => props.font_size}px;
  position: ${(props) => props.position};
  z-index: ${(props) => props.z_index};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  right: ${(props) => props.right}px;
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  padding: ${(props) => props.padding};
  display: ${(props) => props.display};
  grid-template-columns: ${(props) => props.grid_template_column};
  grid-template-rows: ${(props) => props.grid_template_rows};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align_items};
  gap: ${(props) => props.gap}px;
  line-height: ${(props) => props.lineheight}px;
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.text_align};
  background-color: ${(props) => props.background_color};
  border: ${(props) => props.border};
  border-top: ${(props) => props.border_top};
  border-bottom: ${(props) => props.border_bottom};
  border-left: ${(props) => props.border_left};
  border-right: ${(props) => props.border_right};
  border-radius: ${(props) => props.border_radius}px;
  box-shadow: ${(props) => props.box_shadow};
  box-sizing: border-box;
  overflow-x: ${(props) => props.overflowX};
  overflow: ${(props) => props.overflow};
  cursor: ${(props) => props.cursor};
  flex: ${(props) => props.flex};
  font-weight: ${(props) => props.font_weight};
  font-style: ${(props) => props.font_style};
  white-space: ${(props) => props.white_space};
  text-overflow: ${(props) => props.text_overflow};
  word-break: ${(props) => props.word_break};
  flex-direction: ${(props) => props.flex_direction};
  @media ${device.pc} {
    &:hover {
      background-color: ${(props) => props.pc_hover_background_color};
    }
    &::-webkit-scrollbar {
      width: ${(props) => props.scroll_width};
      /* height: 20px; */
    }
    &::-webkit-scrollbar {
      display: ${(props) => (props.scroll_hide ? "none" : "")};
    }
    border: ${(props) => props.pc_border};
    display: ${(props) => props.pc_display};
    grid-template-columns: ${(props) => props.pc_grid_template_column};
    width: ${(props) => props.pc_width};
    height: ${(props) => props.pc_height}px;
    justify-content: ${(props) => props.pc_justify};
    align-items: ${(props) => props.pc_align_items};
    gap: ${(props) => props.pc_gap}px;
    font-size: ${(props) => props.pc_font_size}px;
    font-family: ${(props) => props.pc_font_family};
    line-height: ${(props) => props.pc_lineheight}px;
    padding: ${(props) => props.pc_padding};
    margin: ${(props) => props.pc_margin};
    text-align: ${(props) => props.pc_text_align};
    box-shadow: ${(props) => props.pc_box_shadow};
    border-radius: ${(props) => props.pc_border_radius}px;
    flex: ${(props) => props.pc_flex};
    font-weight: ${(props) => props.pc_font_weight};
    font-style: ${(props) => props.pc_font_style};
    flex-direction: ${(props) => props.pc_flex_direction};
    max-width: ${(props) => props.pc_max_width}px;
    box-sizing: ${(props) => props.pc_box_sizing};
    background-color: ${(props) => props.pc_background_color};
    min-width: ${(props) => props.pc_min_width};
    transform: ${(props) => props.pc_transform};
  }
`;

export default DivTwoPartImprove;
