import React, { useState } from "react";
import TextLine from "../atom/TextLine";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import OnChangeInput from "../atom/OnchangeInput";
import Img from "../atom/Img";
import expandDown from "../../img/expandDown.svg";

interface InputBoxProps {
  title: string;
  title_fontsize?: number;
  title_fontfamily?: string;
  input_type?: string;
  placeholder?: string;
  input_border?: string;
  input_border_bottom?: string;
  input_border_radius?: number;
  input_padding?: string;
  input_lineheight?: number;
  optional?: boolean;
  option_list?: string[];
  option_list_fontfamily?: string;
  option_list_fontsize?: number;
  option_list_gap?: number;
  option_list_border?: string;
  option_list_border_radius?: number;
  essential?: boolean;
  width?: string;
  height?: number;
  pc_height?: number;
  gap?: number;
  tab_gap?: number;
  optionChoose?: string | null;
  setOptionChoose?: React.Dispatch<React.SetStateAction<string | null>>;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  onchange?: (e: any) => void;
  onblur?: () => void;
}

const InputBoxWithTitle: React.FC<InputBoxProps> = (props) => {
  const [optionalState, setOptionalState] = useState<boolean>(false);

  return (
    <DivTwoPartImprove
      display="grid"
      pc_gap={props.gap}
      gap={props.tab_gap}
      lineheight={30}
    >
      <DivTwoPartImprove pc_display="flex" pc_align_items="center">
        {props.essential && (
          <TextLine
            text="*"
            fontsize={20}
            fontcolor="#C83B38"
            margin="0 2px 0 0"
          />
        )}
        <TextLine
          text={props.title}
          pc_fontsize={props.title_fontsize ? props.title_fontsize : 18}
          fontFamily={props.title_fontfamily}
          lineheight={30}
          fontsize={16}
        />
        {props.optional && (
          <TextLine
            text="(optional)"
            fontsize={16}
            fontFamily="regular"
            fontcolor="#818181"
            margin="0 0 0 7px"
          />
        )}
      </DivTwoPartImprove>
      {props.optional ? (
        <DivTwoPartImprove position="relative">
          <DivTwoPartImprove
            display="flex"
            justify="space-between"
            align_items="center"
            onclick={() => setOptionalState(true)}
            height={56}
            pc_height={60}
            border_radius={8}
            border="1px solid #d9d9d9"
            padding="10px 16px"
            pc_padding="12px 16px"
          >
            <TextLine
              width={props.width}
              text={props.optionChoose ? props.optionChoose : props.placeholder}
              fontsize={16}
              fontFamily="regular"
              fontcolor={props.optionChoose ? "black" : "#818181"}
            />
            <Img src={expandDown} width="20" height={20} />
          </DivTwoPartImprove>
          {optionalState && (
            <>
              <div
                onClick={() => setOptionalState(false)}
                style={{
                  position: "fixed",
                  top: "-16%",
                  left: "-73%",
                  width: "200vw",
                  height: "200vh",
                  zIndex: 1,
                }}
              />
              <DivTwoPartImprove
                width="100%"
                position="absolute"
                z_index={10}
                top={0}
                left={0}
                background_color="white"
                border={props.option_list_border}
                border_radius={props.option_list_border_radius}
                display="grid"
                gap={props.option_list_gap ? props.option_list_gap : 11}
                padding="10px 16px"
                pc_padding="12px 16px"
              >
                {props.option_list &&
                  props.option_list.map((value, index) => (
                    <DivTwoPartImprove
                      key={index}
                      font_family={
                        props.option_list_fontfamily
                          ? props.option_list_fontfamily
                          : "medium"
                      }
                      font_size={
                        props.option_list_fontsize
                          ? props.option_list_fontsize
                          : 17
                      }
                      onclick={() => {
                        if (props.setOptionChoose) props.setOptionChoose(value);
                        setOptionalState(false);
                      }}
                    >
                      {value}
                    </DivTwoPartImprove>
                  ))}
              </DivTwoPartImprove>
            </>
          )}
        </DivTwoPartImprove>
      ) : (
        <OnChangeInput
          inputRef={props.inputRef}
          placeholderText={props.placeholder}
          placeholdercolor="#BDBDBD"
          fontsize={17}
          border={props.input_border ? props.input_border : "1px solid #D9D9D9"}
          border_bottom={props.input_border_bottom}
          border_radius={props.input_border_radius}
          width={props.width}
          height={props.height}
          pc_height={props.pc_height}
          type={props.input_type}
          padding={props.input_padding ? props.input_padding : "10px 16px"}
          pc_padding="12px 16px"
          onchange={props.onchange}
          onblur={props.onblur}
          lineheight={props.input_lineheight ? props.input_lineheight : 30}
        />
      )}
    </DivTwoPartImprove>
  );
};

export default InputBoxWithTitle;
