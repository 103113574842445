import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { examUpdate, userInfo } from "../atoms";
import getApi from "../api/getApi";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import customAxios from "../api/handler";
import { GetInvitedList } from "../interface/apiInterface";
import LandingInvited from "../modal/LandingInvited";
import LeftTest from "../modal/ServicePlanLeftTest";
import PreTest from "../modal/LandingPreTest";

const InviteModal = () => {
  const { t } = useTranslation();
  const showToast = useToast();

  const [user, setUser] = useRecoilState(userInfo);
  const [inviteInfo, setInviteInfo] = useState<GetInvitedList>();

  const [leftTestModal, setLeftTestModal] = useState<boolean>(false);
  const [preTestModal, setPreTestModal] = useState<boolean>(false);
  const [inviteModal, setInviteModal] = useState<boolean>(false);

  useEffect(() => {
    if (user?.hasInvite) {
      setInviteModal(true);
      getApi
        .getInvitedList()
        .then(({ data }) => {
          setInviteInfo(data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
  }, [user?.hasInvite]);

  /** 수락 전 미결제 금액과 현재 진행중인 시험 유무 확인 및 환불 금액 체크 */
  const beforeAccept = (props: string) => {
    getApi
      .getProgressingTest()
      .then(() => {
        getApi
          .getPendingTest()
          .then(() => {
            inviteAnswer(props);
            setInviteModal(false);
          })
          .catch(({ response }) => {
            if (response.data.errorCode === "ENTERPRISE-011") {
              setPreTestModal(true);
              setInviteModal(false);
            } else {
              showToast({
                message: t(`errorCode.unknown_server_error`),
              });
            }
          });
      })
      .catch(({ response }) => {
        console.log(response);
        if (
          response?.data.errorCode === "ENTERPRISE-010" ||
          response?.data.errorCode === "TIMEZONE-007"
        ) {
          setLeftTestModal(true);
          setInviteModal(false);
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  const inviteAnswer = (props: string) => {
    customAxios
      .patch(`/api/v1/users/individual/approval`, {
        isVerifiedHostEmailInvite: props,
        hostEmail: inviteInfo?.invitedList.hostEmail,
        hostGroupName: inviteInfo?.invitedList.hostGroupName,
      })
      .then(() => {
        setPreTestModal(false);
        customAxios.get(`/api/v1/hosts`).then(({ data }) => {
          setUser(data?.getHost);
        });
        if (props === "approval") {
          showToast({
            message: `${inviteInfo?.invitedList.hostGroupName} ${t(
              `errorCode.transToMember`,
            )}`,
          });
        }
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-ROLE-003" ||
          response.data.errorCode === "HOST-ROLE-005" ||
          response.data.errorCode === "ENTERPRISE-006"
        ) {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  return (
    <>
      {inviteModal && (
        <LandingInvited
          inviteInfo={inviteInfo}
          setInviteModal={setInviteModal}
          inviteAnswer={inviteAnswer}
          beforeAccept={beforeAccept}
        />
      )}
      {leftTestModal && (
        <LeftTest
          close={setLeftTestModal}
          title="기업 구성원으로 연결 불가"
          textOne="기업 회원은 개인 회원으로 자동 전환 후에 탈퇴가 가능해요."
          textTwo="관리자 또는 구성원 중 ‘내 시험 관리’ 내 시작 직전(시작 시간 기준 3시간 전) 또는 현재 진행 중에 있는 시험이 있어 개인 회원으로 전환 할 수 없어요. 해당 시험의 모든 응시자들이 영상 업로드 단계까지는 완료해야만 회원 전환을 문제없이 진행할 수 있어요."
        />
      )}
      {preTestModal && (
        <PreTest
          setPreTestModal={setPreTestModal}
          inviteAnswer={inviteAnswer}
        />
      )}
    </>
  );
};

export default InviteModal;
