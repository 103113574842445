import React, { useEffect } from "react";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import styled from "styled-components";
import { device } from "../hooks/device";
import useGetTestDelete from "../hooks/useGetTestDelete";
import useScrollLockThree from "../hooks/useScrollLockThree";
import customAxios from "../api/handler";
import { useRecoilState, useRecoilValue } from "recoil";
import { examUpdate, userInfo } from "../atoms";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";

const MyExamTestDelete = ({
  setTestDeleteModal,
  setDeleteDeniedModal,
  hostType,
  testUuid,
}) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userInfo);
  const showToast = useToast();
  const [update, setUpdate] = useRecoilState(examUpdate);

  const goBack = () => {
    setTestDeleteModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const { data } = useGetTestDelete({ testUuid, hostType });

  useScrollLockThree();

  const deleteTest = () => {
    customAxios
      .delete(`/api/v1/tests/delete/${testUuid}?type=${hostType}`)
      .then(() => {
        setTestDeleteModal(false);
        setUpdate(!update);
        showToast({
          message: t(`errorCode.testDelete`),
        });
      })
      .catch(({ response }) => {
        const errorCode = response.data.errorCode;
        if (errorCode === "TIMEZONE-007") {
          setDeleteDeniedModal(true);
          setTestDeleteModal(false);
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  return (
    <>
      <ModalBackD />
      <StUpload>
        <ModalHeaderD title="시험 취소하기" />
        <div className="uploadBody">
          <div className="upBox">
            시험이 삭제됨과 동시에 사용한 적립금은 자동으로 환원돼요.
            <br className="onlyForPc" />
            시험을 취소하고 삭제하시겠어요?
          </div>
          <div className="amountBox">
            <div className="amountTitle">환원 예정 적립금</div>
            <div className="realAmount">
              {data ? Number(data?.testActualAmount)?.toLocaleString() : ""} C
              {/* <span>원</span> */}
            </div>
          </div>
          <div className="infoBox">
            <span>※ 취소 마감 시간과 적립금 환원</span>
            <ul className="infoList">
              <li>
                응시 시작시간 3시간 전까지 취소가 가능하며, 마감 시간 이후에는
                취소가 불가해요.
              </li>
              <li>취소 시 별도의 수수료 없이 전체 사용한 적립금이 환원돼요.</li>
            </ul>
          </div>
          <div className="buttonBox">
            <div className="cancelButton" onClick={() => window.history.back()}>
              취소
            </div>
            <div className="greyBar onlyForMobile" />
            <div className="confirmButton" onClick={() => deleteTest()}>
              취소하기
            </div>
          </div>
        </div>
      </StUpload>
    </>
  );
};

const StUpload = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    display: grid;
    flex-direction: column;
    gap: 48px;
    .onlyForMobile {
      display: none;
    }

    .uploadBody {
      display: grid;
      flex-direction: column;
      gap: 48px;
      font-size: 18px;
      font-family: "medium";
      .upBox {
        line-height: 27px;
        font-size: 18px;
        font-family: "medium";
      }
      .amountBox {
        text-align: center;
        .amountTitle {
          line-height: 27px;
          font-size: 18px;
          font-family: "medium";
        }
        .realAmount {
          font-family: "semibold";
          font-size: 28px;
          line-height: 41px;
          color: #2276dc;
          span {
            margin-left: 10px;
            color: black;
          }
        }
      }
      .infoBox {
        line-height: 24px;
        font-size: 16px;
        span {
          font-family: "semibold";
        }
        .infoList {
          display: grid;
          flex-direction: column;
          gap: 5px;
          padding-left: 19px;
          font-family: "regular";
        }
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        align-items: center;
        gap: 21px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        .cancelButton {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          font-family: "medium";
          line-height: 25px;
          border: 1px solid #000000;
          border-radius: 43px;
          height: 52px;
          width: 204px;
          cursor: pointer;
        }
        .confirmButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 204px;
          height: 52px;
          text-align: center;
          background: #20315b;
          border-radius: 43px;
          color: white;
          cursor: pointer;
        }
        .greyBar {
          width: 1px;
          height: 16px;
          background-color: #e6e6e6;
        }
      }
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .onlyForPc {
      display: none !important;
    }
    .uploadBody {
      display: grid;
      flex-direction: column;
      gap: 24px;
      .upBox {
        line-height: 24px;
      }
      .amountBox {
        text-align: center;
        .amountTitle {
          line-height: 24px;
          font-family: "medium";
        }
        .realAmount {
          font-family: "semibold";
          font-size: 20px;
          line-height: 30px;
          color: #2276dc;
          span {
            margin-left: 10px;
            color: black;
          }
        }
      }
      .infoBox {
        span {
          font-family: "semibold";
          line-height: 24px;
        }
        .infoList {
          line-height: 24px;
          padding-left: 19px;
        }
      }
      .buttonBox {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: center;
        align-items: center;
        gap: 41.55px;
        color: #0072de;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        .confirmButton {
          width: 63px;
          cursor: pointer;
        }
        .cancelButton {
          width: 63px;
          text-align: center;
          cursor: pointer;
        }
        .greyBar {
          width: 1px;
          height: 16px;
          background-color: #e6e6e6;
        }
      }
    }
  }
`;

export default MyExamTestDelete;
