import React from "react";
import checkBox from "../../img/checkbox_filled.svg";
import TextLine from "../atom/TextLine";
import styled from "styled-components";

interface CheckBoxProps {
  checked?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  text?: string;
  gap?: number;
  lineheight?: number;
}

const CheckBoxWithText: React.FC<CheckBoxProps> = (props) => {
  return (
    <StLine
      gap={props.gap}
      lineheight={props.lineheight}
      onClick={() => {
        if (props.setChecked) {
          props.setChecked(!props.checked);
        }
      }}
    >
      <>
        {props.checked ? <img src={checkBox} /> : <div className="emptyBox" />}
      </>
      <TextLine text={props.text} />
    </StLine>
  );
};

const StLine = styled.div<CheckBoxProps>`
  display: grid;
  grid-template-columns: 22px auto;
  align-items: center;
  gap: ${(props) => props.gap}px;
  line-height: ${(props) => props.lineheight}px;
  cursor: pointer;
  .emptyBox {
    width: 22px;
    height: 22px;
    border: 1px solid #818181;
    border-radius: 4px;
    box-sizing: border-box;
  }
`;

export default CheckBoxWithText;
