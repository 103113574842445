import axios from "axios";
import { getCookie } from "../Cookie";
import { useNavigate } from "react-router-dom";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";

// export const baseURL = "https://asia.server.proctormatic.com";
export const baseURL = process.env.REACT_APP_SERVER_ADDRESS;

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_ADDRESS,
  // withCredentials: true,
});

const AxiosInterceptor = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useToast();

  customAxios.interceptors.request.use(
    (config) => {
      if (!config.url.endsWith("kr")) {
        if (config.url.includes("?")) {
          config.url = config.url + "&offset=-540&lang=kr";
        } else {
          config.url = config.url + "?offset=-540&lang=kr";
        }
      }
      const accessToken = getCookie("token");
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    },
    ({ data }) => {
      return Promise.reject(data);
    }
  );

  customAxios.interceptors.response.use(
    (data) => {
      // console.log(data);
      return data;
    },
    (error) => {
      const formattedError = error?.response || error;
      if (formattedError?.code === "ERR_NETWORK") {
        showToast({
          message: t(`errorCode.network`),
        });
      } else if (
        formattedError?.status === 499 ||
        formattedError?.status === 401 ||
        formattedError?.status === 403
      ) {
        navigate(`/error`);
        return Promise.reject(error);
      } else if (formattedError?.status === 500) {
        if (formattedError?.data?.errorData?.errorType === "payment") {
          showToast({
            message: t(`errorCode.payment_server_error`),
          });
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
        return Promise.reject(error);
      } else if (
        formattedError?.status === 400 ||
        formattedError?.status === 404
      ) {
        return Promise.reject(error);
      } else {
        showToast({
          message: t(`errorCode.unknown_server_error`),
        });
      }
    }
  );

  return children;
};

export default customAxios;
export { AxiosInterceptor };
