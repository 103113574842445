import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetTestDelete = ({ testUuid, hostType }) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getTestDelete({ testUuid, hostType, utc });
    return data;
  };
  return useQuery("testDelete", fetcher);
};

export default useGetTestDelete;
