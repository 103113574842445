import React, { forwardRef } from "react";
import { styled } from "styled-components";

interface TextProps {
  textRef?: React.RefObject<HTMLTextAreaElement>;
  width?: string;
  height?: number;
  placeholderText?: string;
  fontSize?: number;
  fontcolor?: string;
  placeholdercolor?: string;
  padding?: string;
  borderradius?: number;
  border?: string;
  type?: string;
  value?: string;
  onblur?: () => void;
  onchange?: (e: any) => void;
}

const TextArea = forwardRef<HTMLInputElement, TextProps>((props) => {
  return (
    <StInput
      value={props.value}
      ref={props.textRef}
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      placeholdercolor={props.placeholdercolor}
      placeholder={props.placeholderText}
      padding={props.padding}
      borderradius={props.borderradius}
      border={props.border}
      type={props.type}
      onChange={props.onchange}
      onBlur={props.onblur}
    />
  );
});

const StInput = styled.textarea<TextProps>`
  width: ${(props) => props.width};
  min-height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  font-family: "regular";
  padding: ${(props) => props.padding};
  &::placeholder {
    color: ${(props) => props.placeholdercolor};
    font-family: "light";
  }
  outline: none;
  box-sizing: border-box;
  border-radius: ${(props) => props.borderradius}px;
  border: ${(props) => props.border};
  resize: none;
`;

export default TextArea;
