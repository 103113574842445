import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";

// img
import membership from "../img/membership.svg";

import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import { device } from "../hooks/device";

interface LandingInvitedProps {
  beforeAccept: (props: string) => void;
  inviteAnswer: (props: string) => void;
  inviteInfo: any;
  setInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const LandingInvited: React.FC<LandingInvitedProps> = ({
  beforeAccept,
  inviteAnswer,
  inviteInfo,
  setInviteModal,
}) => {
  const goBack = () => {
    setInviteModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);

  useScrollLockThree();

  return (
    <>
      <ModalBackD modalOff={null} />
      <StLandingInvited>
        <div className="upBox">
          <div>
            <div className="enterprise">기업 회원</div>
            <div className="info">
              적립금 충전과
              <br />
              공유를 통한 편리한 시험 운영
            </div>
          </div>
          <img src={membership} />
        </div>
        <div className="downBox">
          {inviteInfo?.invitedList?.etc >= 1 ? (
            <div>
              <div className="enterpriseName">
                수락 대기 중인 기업 초대 ({inviteInfo?.invitedList?.etc + 1}건)
              </div>
              <div className="inviteMention">
                {inviteInfo?.invitedList?.hostName}(
                {inviteInfo?.invitedList?.hostEmail})님 외{" "}
                {inviteInfo?.invitedList?.etc}명이 회원님을 기업 구성원으로
                초대하였어요.
              </div>
            </div>
          ) : (
            <div>
              <div className="enterpriseName">
                {inviteInfo?.invitedList?.hostGroupName}
              </div>
              <div className="inviteMention">
                {inviteInfo?.invitedList?.hostName}(
                {inviteInfo?.invitedList?.hostEmail})님이 회원님을 기업
                구성원으로 초대하였어요.
              </div>
            </div>
          )}
          {inviteInfo?.invitedList?.etc >= 1 ? (
            <div className="buttonBox">
              <div
                className="goToMessageBox"
                onClick={() => navigate(`/invited`)}
              >
                메시지 수신함 바로가기
              </div>
            </div>
          ) : (
            <div className="buttonBoxTwo">
              <div
                className="cancelButton"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  inviteAnswer("refusal");
                  setInviteModal(false);
                }}
              >
                거절
              </div>
              <div
                className="confirmButton"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  beforeAccept("approval");
                  setInviteModal(false);
                }}
              >
                수락
              </div>
            </div>
          )}
          <div
            className="delay"
            onClick={() => {
              inviteAnswer("pending");
              setInviteModal(false);
            }}
          >
            나중에 메시지 수신함에서 확인
          </div>
        </div>
      </StLandingInvited>
    </>
  );
};

const StLandingInvited = styled.div`
  position: fixed;
  bottom: 16px;
  left: 50%;
  z-index: 53;
  transform: translate(-50%, 0);
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  .upBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f97e0d;
    color: white;
    .enterprise {
      font-size: 32px;
      font-family: "extrabold";
      line-height: 38px;
      margin-bottom: 12px;
    }
    .info {
      font-size: 18px;
      font-family: "semibold";
      line-height: 27px;
    }
  }
  .downBox {
    .enterpriseName {
      font-size: 28px;
      font-family: "bold";
      line-height: 41px;
      color: #252525;
    }
    .inviteMention {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin: 16px 0 32px;
    }
    .delay {
      font-size: 13px;
      font-family: "medium";
      line-height: 19px;
      color: #818181;
      margin-top: 24px;
      text-align: center;
      cursor: pointer;
    }
    .buttonBox,
    .buttonBoxTwo {
      align-items: center;
      justify-content: center;
      gap: 21px;
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid black;
        box-sizing: border-box;
        border-radius: 42px;
      }
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        color: white;
        background-color: #20315b;
        border-radius: 42px;
      }
      .goToMessageBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 252px;
        height: 52px;
        color: white;
        background-color: #20315b;
        border-radius: 43px;
        padding: 15px 48px;
        box-sizing: border-box;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
        margin-top: 4px;
        cursor: pointer;
      }
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto-fill;
    }
    .buttonBoxTwo {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
  @media ${device.pc} {
    width: 580px;
    left: 50%;
    bottom: 50%;
    box-sizing: border-box;
    transform: translate(-50%, 50%);
    .upBox {
      padding: 48px 60px;
    }
    .downBox {
      padding: 60px;
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    /* height: 425px; */
    .upBox {
      padding: 22px 24px;
      .enterprise {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 8px;
      }
      .info {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .downBox {
      padding: 34px 24px 24px;
      .enterpriseName {
        font-size: 20px;
        font-family: "bold";
        line-height: 30px;
      }
      .inviteMention {
        font-size: 16px;
        font-family: "regular";
        line-height: 24px;
        margin: 20px 0 24px;
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto-fill;
        gap: 10px;
        .cancelButton,
        .confirmButton {
          width: 143px;
          height: 42px;
          padding: 15px 11px;
          box-sizing: border-box;
        }
        .goToMessageBox {
          width: 204px;
          height: 42px;
          padding: 15px 11px;
          font-size: 14px;
          font-family: "medium";
          line-height: 21px;
          margin: 0;
        }
      }
      .buttonBoxTwo {
        gap: 10px;
        font-size: 14px;
        font-family: "medium";
        .cancelButton,
        .confirmButton {
          width: 143px;
          height: 42px;
        }
      }
      .delay {
        margin-top: 36px;
      }
    }
  }
`;

export default LandingInvited;
