import React from "react";
import styled from "styled-components";

// styled component

// img
import Back from "../img/HomeBG.jpg";
import { device } from "../hooks/device";
import HomeOne from "./HomeOne.jsx";
import HomeTwo from "./HomeTwo.tsx";
import HomeThree from "./HomeThree.jsx";
import HomeFour from "./HomeFour.tsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Home1.css";
// import 'swiper/css/navigation';

const Home1 = ({ top }) => {
  return (
    <StHome1 ref={top}>
      <Swiper
        style={{
          "--swiper-pagination-color": "#D9D9D9",
          // "--swiper-pagination-bullet-color":"rgb(217, 217, 217,0.3);",
          "--swiper-pagination-bullet-inactive-color": "rgb(217, 217, 217)",
          "--swiper-pagination-bullet-inactive-opacity": "0.3",
          marginBottom: "30px !important",
        }}
        loop={true}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
        }}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide>
          <HomeOne />
        </SwiperSlide>
        <SwiperSlide>
          <HomeTwo />
        </SwiperSlide>
        <SwiperSlide>
          <HomeThree />
        </SwiperSlide>
        <SwiperSlide>
          <HomeFour />
        </SwiperSlide>
      </Swiper>
    </StHome1>
  );
};

const StHome1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 675px;
  background: radial-gradient(
    189.33% 88.48% at 17.27% 20.89%,
    #0d2256 0%,
    #070f21 100%
  );
  @media ${device.pc} {
    min-width: 1440px;
  }
`;

export default Home1;
