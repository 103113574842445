import React from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface ImgProps {
  position?: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  display?: string;
  pc_display?: string;
  width?: string;
  height?: number;
  margin?: string;
  pcMargin?: string;
  padding?: string;
  src: string;
  cursor?: string;
  objectFit?: string;
  transform?: string;
  onclick?: () => void;
  justify_content?: string;
  align_items?: string;
  tab_width?: string;
  tab_height?: string;
}

const Img: React.FC<ImgProps> = (props) => {
  return (
    <StImage
      position={props.position}
      top={props.top}
      left={props.left}
      right={props.right}
      bottom={props.bottom}
      display={props.display}
      pc_display={props.pc_display}
      width={props.width}
      height={props.height}
      margin={props.margin}
      pcMargin={props.pcMargin}
      padding={props.padding}
      src={props.src}
      onClick={props.onclick}
      objectFit={props.objectFit}
      transform={props.transform}
      cursor={props.cursor}
      justify_content={props.justify_content}
      align_items={props.align_items}
      tab_width={props.tab_width}
      tab_height={props.tab_height}
    />
  );
};

const StImage = styled.img<ImgProps>`
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  display: ${(props) => props.display};
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  object-fit: ${(props) => props.objectFit};
  transform: ${(props) => props.transform};
  justify-content: ${(props) => props.justify_content};
  align-items: ${(props) => props.align_items};
  cursor: ${(props) => props.cursor};
  @media ${device.tabMob} {
    width: ${(props) => props.tab_width};
    height: ${(props) => props.tab_height};
  }
  @media ${device.pc} {
    display: ${(props) => props.pc_display};
    margin: ${(props) => props.pcMargin};
  }
`;

export default Img;
