import { tradeDetailRequest } from "../hooks/api/useGetTradeDetailManager";
import {
  CommentDetailResponse,
  GetMyPageResponse,
  GetTradeDetail,
} from "../interface/apiInterface";
import { GetCreditProps, UidRequestProps } from "../interfaces";
import customAxios from "./handler";

const getApi = {
  getFinishedExam: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/test-verified`),
  getAwaitExam: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/test-pending`),
  getDefaultExam: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/test-verifying`),
  getInvitedList: () =>
    customAxios.get(`/api/v1/users/individual/invited-list`),
  getMessageBox: () => customAxios.get(`/api/v1/hosts/messageBox`),
  getMyCredit: () =>
    customAxios.get(`/api/v1/payments/enterprise/manager/credits`),
  getCreditUseMember: () =>
    customAxios.get(`/api/v1/payments/enterprise/manager/credits/latest-usage`),
  getCreditUseMaster: ({ value }: { value: string }) =>
    customAxios.get(
      `/api/v1/payments/enterprise/manager/credits/list?type=${value}`
    ),
  getCreditRefundList: () =>
    customAxios.get(`/api/v1/payments/enterprise/credits/refund-list`),
  getEnterpriseInvite: () =>
    customAxios.get(`/api/v1/users/enterprise/manager/invited-list`),
  getEnterpriseTestOnGoing: () =>
    customAxios.get(`/api/v1/users/enterprise/manager/connectedMember`),
  getEnterpriseMemberList: ({ sort }: { sort: string }) =>
    customAxios.get(`/api/v1/users/enterprise/manager/tradeList?sort=${sort}`),
  getUserData: () => customAxios.get(`/api/v1/hosts`),
  getCreditForRefund: () =>
    customAxios.get(`/api/v1/payments/enterprise/credits/pre-cancel-list`),
  getRefundSpecific: ({ refundId }: { refundId: string }) =>
    customAxios.get(
      `/api/v1/payments/enterprise/credits/cancel-list?refundGroupId=${refundId}`
    ),
  getMyExam: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/test-summary`),
  getNoticeList: ({ nowPageUniversal }: { nowPageUniversal: number }) =>
    customAxios.get(`/api/v1/notices?page=${nowPageUniversal}&pageCount=15`),
  getQnAList: ({ listPage }: { listPage: number }) =>
    customAxios.get(`/api/v1/questions?page=${listPage}&pageCount=15`),
  getQnADetail: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/questions/${uid}`),
  getQnAEdit: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/questions/patch/${uid}`),
  getQnACount: () => customAxios.get(`/api/v1/questions/count`),
  getNoticeCount: () => customAxios.get(`/api/v1/notices/count`),
  getNoticeDetail: ({ id }: { id: string }) =>
    customAxios.get(`/api/v1/notices/${id}`),
  getReportAIJudge: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/judge-summary`),
  getReportList: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/tester-list`),
  getReportAIEvent: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/event-summary`),
  getReportUserList: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/tests/${uid}/report/uploader-list`),
  getReportSettingPhoto: ({
    testId,
    testerId,
  }: {
    testId: string;
    testerId: string;
  }) =>
    customAxios.get(
      `/api/v1/tests/${testId}/report?testerId=${testerId}&purpose=setting-url`
    ),
  getReport: ({
    testId,
    testerId,
    rawDataState,
  }: {
    testId: string;
    testerId: string;
    rawDataState: string;
  }) =>
    customAxios.get(
      `/api/v1/tests/${testId}/report/${testerId}?rawData=${rawDataState}`
    ),
  getTestDelete: ({
    testUuid,
    hostType,
  }: {
    testUuid: string;
    hostType: string;
  }) => customAxios.get(`/api/v1/tests/delete/${testUuid}?type=${hostType}`),
  getMemberUse: ({ uid }: UidRequestProps) =>
    customAxios.get(`/api/v1/users/enterprise/manager/${uid}/information`),
  getMemberCreditUseList: ({ uid, type }: { uid: string; type: string }) =>
    customAxios.get(
      `/api/v1/users/enterprise/manager/${uid}/tradeList?type=${type}`
    ),
  getIndividualRefund: () =>
    customAxios.get(`/api/v1/users/individual/scheduled-refund-test`),
  getEnterpristChargeConfirm: ({ preUid }: { preUid: string }) =>
    customAxios.get(`/api/v1/payments/status/${preUid}`),
  getBannerInfo: () => customAxios.get(`/api/v1/coupons/promotion`),
  getCreditInfo: () =>
    customAxios.get<GetCreditProps>(`/api/v1/payments/credits`),
  getMyPageInfo: () =>
    customAxios.get<GetMyPageResponse>(`/api/v1/hosts/mypage`),
  getProgressingTest: () => customAxios.get(`/api/v1/users/progressing-test`),
  getPendingTest: () => customAxios.get(`/api/v1/users/pending-test`),
  tradeDetailManager: ({ tradeId, type }: tradeDetailRequest) =>
    customAxios.get<GetTradeDetail>(
      `/api/v1/payments/enterprise/manager/credits/list/detail?paymentsCreditTradeId=${tradeId}&type=${type}`
    ),
  tradeDetailInd: ({ tradeId, type }: tradeDetailRequest) =>
    customAxios.get<GetTradeDetail>(
      `/api/v1/payments/individual/credits/list/detail?paymentsCreditTradeId=${tradeId}&type=${type}`
    ),
  commentDetail: ({
    questionUuid,
    questionComponentUuid,
  }: {
    questionUuid: string;
    questionComponentUuid: string;
  }) =>
    customAxios.get<CommentDetailResponse>(
      `/api/v1/questions/patch/component?questionUuid=${questionUuid}&questionComponentUuid=${questionComponentUuid}`
    ),
};

export default getApi;
