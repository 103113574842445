import React, { useEffect, useRef, useState } from "react";
import ModalOneButtonTemplate from "../../atomic/template/ModalOneButtonTemplate";
import closeButton from "../img/x.svg";
import styled from "styled-components";
import Input from "../../atomic/atom/Input";
import deleteImg from "../img/deleteImg.svg";
import TextArea from "../../atomic/atom/TextArea";
import TextLine from "../../atomic/atom/TextLine";
import InputBoxWithTitle from "../../atomic/molecule/InputBoxWithTitle";
import DivTwoPartImprove from "../../atomic/atom/DivTwoPartImprove";
import PageTemplate from "../../atomic/template/PageTemplate";
import CheckBoxWithText from "../../atomic/molecule/CheckBoxWithText";
import Button from "../../atomic/atom/Button";
import customAxios from "../../api/handler";
import { device } from "../../hooks/device";
import errorMark from "../../img/error.svg";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageIs } from "../../atoms";
import AlertMessage from "../../atomic/molecule/AlertMessage";
import focusing from "../../hooks/focusing";
import { ref } from "joi";
import { useTranslation } from "react-i18next";
import useToast from "../../hooks/useToast";

const ConsultRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const numberCheck = /^[+-]?\d*\.?\d+$/;
  const [buttonActivate, setButtonActivate] = useState<boolean>(false);
  const [name, setName] = useState<string | null>(null);
  const [corporation, setCorporation] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [requestText, setRequestText] = useState<string>("");
  const [expectTester, setExpectTester] = useState<string | null>(null);
  const [visitPath, setVisitPath] = useState<string | null>(null);
  const [privateInfoAgreement, setPrivateInfoAgreement] =
    useState<boolean>(false);
  const [newsletterAgreement, setNewsletterAgreement] =
    useState<boolean>(false);

  const showToast = useToast();

  // error
  const emailRef = useRef<HTMLInputElement | null>(null);
  const phoneRef = useRef<HTMLInputElement | null>(null);

  const expectTesterList: string[] = [
    "1~10명",
    "11~30명",
    "31~50명",
    "51~100명",
    "101~300명",
    "301~500명",
    "501~1000명",
    "1001~2000명",
    "2001명 이상",
  ];
  const visitPathList: string[] = [
    "인스타그램",
    "유튜브",
    "전시회/박람회",
    "커뮤니티",
    "지인추천",
  ];

  useEffect(() => {
    activatingButton();
  }, [
    name,
    corporation,
    email,
    phoneNumber,
    requestText,
    privateInfoAgreement,
  ]);

  /** 전화번호 형식 통일화 */
  const phoneNumberUnion = (num: string) => {
    let temp = num.split("-");
    let phoneNumber = "";
    for (let i = 0; i < temp.length; i++) {
      phoneNumber = phoneNumber + temp[i];
    }
    setPhoneNumber(phoneNumber);
  };

  /** 버튼 활성화를 위해 필요한 조건 나열 */
  const activatingButton = () => {
    if (
      name &&
      corporation &&
      email &&
      phoneNumber &&
      requestText &&
      privateInfoAgreement
    ) {
      setButtonActivate(true);
    } else {
      setButtonActivate(false);
    }
  };

  /** 도입 문의 요청 보내기 */
  const postRequestDocument = () => {
    customAxios
      .post(`/api/v1/hosts/intro`, {
        introName: name,
        introGroup: corporation,
        introEmail: email,
        introPhone: phoneNumber,
        introExpectTester: expectTester,
        introVisitPath: visitPath,
        introContext: requestText.split("\n").join("<br/>"),
        introLegalAgree: privateInfoAgreement,
        introMarketingAgree: newsletterAgreement,
      })
      .then(() => {
        navigate(`complete`);
      })
      .catch(({ response }) => {
        // navigate(`complete`);
        const errorCode = response.data.errorCode;
        if (errorCode === "DTO-VALIDATION-FAILED") {
          focusing({ ref: emailRef });
          showToast({ message: t(`errorCode.emailform_error`) });
        } else if (errorCode === "VERIFY-SMS-002") {
          focusing({ ref: phoneRef });
          showToast({ message: t(`errorCode.verify_sms_002`) });
        }
      });
  };

  return (
    <PageTemplate display="flex" direction="column">
      <StRequest>
        <div className="header">
          <DivTwoPartImprove
            font_color="#2276DC"
            font_family="bold"
            pc_font_size={28}
            font_size={20}
            margin="12px auto"
          >
            프록토매틱 솔루션 도입 상담 문의
          </DivTwoPartImprove>
          <DivTwoPartImprove pc_font_size={16} margin="0 auto" font_size={14}>
            상담을 신청해 주시면 빠르게 연락을 드리겠습니다.
          </DivTwoPartImprove>
        </div>
        <div className="inputBody">
          <div className="inputGroup">
            <InputBoxWithTitle
              width="100%"
              height={60}
              title="성함"
              placeholder="성함"
              essential={true}
              gap={8}
              tab_gap={2}
              onchange={(e) => setName(e.target.value)}
              input_border_radius={8}
            />
            <InputBoxWithTitle
              width="100%"
              height={60}
              title="소속기관"
              placeholder="소속기관"
              gap={8}
              tab_gap={2}
              essential={true}
              onchange={(e) => setCorporation(e.target.value)}
              input_border_radius={8}
            />
          </div>
          <InputBoxWithTitle
            inputRef={emailRef}
            width="100%"
            height={60}
            title="이메일"
            placeholder="이메일"
            essential={true}
            gap={8}
            tab_gap={2}
            onchange={(e) => setEmail(e.target.value)}
            input_border_radius={8}
          />
          <DivTwoPartImprove>
            <InputBoxWithTitle
              inputRef={phoneRef}
              width="100%"
              height={60}
              title="전화번호"
              placeholder="010-1234-5678"
              essential={true}
              gap={8}
              tab_gap={2}
              onchange={(e) => {
                phoneNumberUnion(e.target.value);
              }}
              input_border_radius={8}
            />
            <AlertMessage
              text="문의에 대한 답변은 전화로 안내 드립니다."
              pc_fontsize={16}
              fontsize={14}
            />
          </DivTwoPartImprove>
          <div className="inputGroup">
            <InputBoxWithTitle
              width="100%"
              height={60}
              title="예상 응시자 수"
              optional={true}
              option_list={expectTesterList}
              optionChoose={expectTester}
              option_list_border="1px solid #D9D9D9"
              option_list_border_radius={8}
              setOptionChoose={setExpectTester}
              placeholder="선택 안함"
              gap={8}
              tab_gap={2}
              input_border_radius={8}
            />
            <InputBoxWithTitle
              width="100%"
              height={60}
              title="방문 경로"
              optional={true}
              option_list={visitPathList}
              optionChoose={visitPath}
              option_list_border="1px solid #D9D9D9"
              option_list_border_radius={8}
              setOptionChoose={setVisitPath}
              placeholder="선택 안함"
              gap={8}
              tab_gap={2}
              input_border_radius={8}
            />
          </div>
          <DivTwoPartImprove>
            <DivTwoPartImprove display="flex">
              <TextLine
                text="*"
                fontsize={20}
                fontcolor="#C83B38"
                height={29}
                margin="0 2px 0 0"
              />
              <TextLine text="문의내용" fontsize={18} height={21} />
            </DivTwoPartImprove>
            <TextArea
              width="100%"
              height={143}
              placeholderText="문의내용"
              placeholdercolor="#BDBDBD"
              fontSize={17}
              border="1px solid #D9D9D9"
              borderradius={8}
              padding="12px 16px"
              onchange={(e) => setRequestText(e.target.value)}
            />
          </DivTwoPartImprove>
        </div>
        <DivTwoPartImprove
          background_color="#EEF0F3"
          display="grid"
          gap={8}
          padding="12px 16px"
          margin="30px 0 0 0"
          border="1px solid #d9d9d9"
          border_radius={8}
        >
          <TextLine
            pc_fontsize={17}
            fontFamily="medium"
            text="개인정보 수집 및 이용에 관한 안내"
            lineheight={36}
            fontsize={16}
          />
          <DivTwoPartImprove display="grid" lineheight={28} font_size={14}>
            <TextLine text="수집항목 : (필수)회사명, 이름, 연락처, 이메일" />
            <TextLine text="수집목적 : 프록토매틱도입 상담 진행, 솔루션 업데이트 소식 및 뉴스레터 발송 " />
            <TextLine text="이용기간 : 개인정보 이용 목적 달성 시까지" />
            <TextLine
              text="위의 개인정보 수집.이용 동의를 거부하실 수 있으면, 거부 시 위 서비스
            이용에 제한이 있을 수 있습니다."
            />
          </DivTwoPartImprove>
        </DivTwoPartImprove>
        <DivTwoPartImprove margin="14px 0 60px 0">
          <CheckBoxWithText
            text="개인정보 및 수집 이용에 동의합니다. (필수)"
            lineheight={36}
            gap={6}
            checked={privateInfoAgreement}
            setChecked={setPrivateInfoAgreement}
          />
          <CheckBoxWithText
            text="뉴스레터 수신에 동의합니다. (선택)"
            gap={6}
            lineheight={36}
            checked={newsletterAgreement}
            setChecked={setNewsletterAgreement}
          />
        </DivTwoPartImprove>
        <Button
          width="204px"
          height={42}
          pc_height={52}
          border="none"
          border_radius={43}
          font_color="white"
          font_size={14}
          pc_fontsize={17}
          font_family="medium"
          backgroundcolor={buttonActivate ? "#ED893E" : "#D9D9D9"}
          text="도입 상담 문의"
          margin="0 auto"
          cursor={buttonActivate ? "pointer" : "default"}
          disabled={!buttonActivate}
          onclick={() => {
            postRequestDocument();
          }}
        />
      </StRequest>
      <Outlet />
    </PageTemplate>
  );
};

const StRequest = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 60px;
  box-sizing: border-box;
  border: 1px solid #d6d9dd;
  border-radius: 24px;
  /* margin: 140px auto; */
  margin-top: 90px;
  margin-bottom: 30px;
  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    img {
      margin: 4px 0 4px auto;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .inputBody {
    display: grid;
    gap: 20px;
    .inputGroup {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  }
  .closeButton {
  }
  @media ${device.tabMob} {
    width: 100%;
    max-width: 800px;
    padding: 24px;
    margin: 50px auto;
    border: none;
    .header {
      margin-bottom: 24px;
    }
    .inputBody {
      .inputGroup {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
`;

export default ConsultRequest;
