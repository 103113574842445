import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import { baseURL } from "../api/handler";
import customAxios from "../api/handler";
import { useNavigate } from "react-router-dom";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { examUpdate } from "../atoms";

const TestDelete = ({ 
  setTestDeleteModal, 
  testId, 
  // setTestListCount 
}) => {
  const { t } = useTranslation();
  // Noah
  const [update, setUpdate] = useRecoilState(examUpdate);
  const goBack = () => {
    setTestDeleteModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const navigate = useNavigate();
  const showToast = useToast();

  const deleteTest = () => {
    customAxios
      .delete(`${baseURL}/api/v1/tests/delete/completed/${testId}`)
      .then(() => {
        if(window.location.href.split("/").indexOf("myexam") === -1) {
          navigate(`/myexam`);
        } else {
          setUpdate(!update);
        }
        showToast({ message: t(`errorCode.testDelete`) });
        setTestDeleteModal(false);
      })
      .catch(({ response }) => {
        showToast({
          message: t(`errorCode.unknown_server_error`),
        });
      });
  };

  return (
    <>
      <ModalBackD />
      <StTestDelete>
        <ModalHeaderD title="시험 삭제하기" />
        <div className="bodyPart">
          <div className="middleBox">
            한번 삭제된 시험은 다시 복원할 수 없어요. <br />
            시험을 정말 삭제하시겠어요?
          </div>
          <div className="buttonBox">
            <div
              className="cancelButton"
              onClick={() => {
                window.history.back();
              }}
            >
              취소
            </div>
            <div className="onlyForMobile greyBar" />
            <div
              className="confirmButton"
              onClick={() => {
                deleteTest();
              }}
            >
              삭제하기
            </div>
          </div>
        </div>
      </StTestDelete>
    </>
  );
};

const StTestDelete = styled.div`
  position: fixed;
  background-color: white;
  @media ${device.pc} {
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    padding: 60px;
    .onlyForMobile {
      display: none;
    }
    .bodyPart {
      .middleBox {
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        margin: 48px 0;
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        align-items: center;
        gap: 21px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        .cancelButton {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          font-family: "medium";
          line-height: 25px;
          border: 1px solid #000000;
          border-radius: 43px;
          height: 52px;
          width: 204px;
          margin: 0 auto;
          cursor: pointer;
        }
        .confirmButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 204px;
          height: 52px;
          text-align: center;
          background: #20315b;
          border-radius: 43px;
          color: white;
          cursor: pointer;
        }
        .greyBar {
          width: 1px;
          height: 16px;
          background-color: #e6e6e6;
        }
      }
    }
  }
  @media ${device.tabMob} {
    left: 50%;
    bottom: 16px;
    z-index: 53;
    transform: translate(-50%, 0);
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    .bodyPart {
      .middleBox {
        margin-bottom: 24px;
        line-height: 24px;
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: center;
        align-items: center;
        gap: 41.55px;
        color: #0072de;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        .confirmButton {
          width: 63px;
          cursor: pointer;
        }
        .cancelButton {
          width: 63px;
          text-align: center;
          cursor: pointer;
        }
        .greyBar {
          width: 1px;
          height: 16px;
          background-color: #e6e6e6;
        }
      }
    }
  }
`;

export default TestDelete;
