import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import useModal from "../hooks/useModal";
import useGetIndividualRefund from "../hooks/useGetIndividualRefund";

const PreTest = ({ setPreTestModal, inviteAnswer }) => {
  const goBack = () => {
    setPreTestModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const { data } = useGetIndividualRefund();
  useModal(setPreTestModal);
  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StPreTest>
        <ModalHeaderD title="기업 회원 전환에 따른 진행 예정 시험 취소" />
        <div className="bucket">
          <div className="infoBox">
            ‘내 시험 관리’ 내 진행 예정에 있는 시험이 {data?.beforeStartTestCount}건이 있어요. 기업
            회원으로 전환 시, 예정된 모든 시험이 취소되며, 사용한 적립금은
            자동으로 환원돼요. 시험을 취소하고 기업 회원 전환을 계속 하시겠어요?
          </div>
          <div className="priceBox">
            <div className="priceInfo">환원 예정 적립금</div>
            <div className="realPrice">
              {Number(data?.totalRefundableAmount)?.toLocaleString()} C
              {/* <span> C</span> */}
            </div>
          </div>
          <div className="processBox">
            <div className="processTitle">시험 취소에 따른 적립금 환원</div>
            <ul>
              <li>취소 시 별도의 수수료 없이 전체 사용한 적립금이 환원돼요.</li>
            </ul>
          </div>
          <div className="buttonBox">
            <div
              className="cancelButton"
              onClick={() => {
                setPreTestModal(false);
              }}
            >
              취소
            </div>
            <div className="columnBar onlyForMobile" />
            <div
              className="confirmButton"
              onClick={() => inviteAnswer("approval")}
            >
              계속하기
            </div>
          </div>
        </div>
      </StPreTest>
    </>
  );
};

const StPreTest = styled.div`
  position: fixed;
  z-index: 53;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .bucket {
    display: flex;
    flex-direction: column;
    .infoBox {
      font-size: 16px;
      line-height: 24px;
    }
    .priceBox {
      display: flex;
      flex-direction: column;
      text-align: center;
      .priceInfo {
        font-size: 16px;
        font-family: "medium";
        line-height: 24px;
      }
      .realPrice {
        font-size: 20px;
        font-family: "semibold";
        line-height: 30px;
        color: #2276dc;
        span {
          color: black;
        }
      }
    }
    .processBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .processTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      ul {
        display: flex;
        flex-direction: column;
        padding-left: 19px;
        margin: 0;
        li {
          line-height: 24px;
        }
      }
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 41px;
      padding: 4.5px 0;
      box-sizing: border-box;
      .columnBar {
        width: 1px;
        height: 16px;
        background-color: #e6e6e6;
      }
      .cancelButton,
      .confirmButton {
        display: flex;
        justify-content: center;
        width: 63px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        color: #0072de;
        cursor: pointer;
      }
    }
  }
  @media ${device.pc} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 580px;
    padding: 60px;
    gap: 48px;
    .onlyForMobile {
      display: none !important;
    }
    .bucket {
      display: flex;
      flex-direction: column;
      gap: 48px;
      .infoBox {
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
      }
      .priceBox {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .priceInfo {
          font-size: 18px;
          font-family: "medium";
          line-height: 27px;
        }
        .realPrice {
          font-size: 28px;
          line-height: 41px;
        }
      }
      .processBox {
        display: flex;
        flex-direction: column;
        .processTitle {
          font-family: "semibold";
          line-height: 24px;
        }
        ul {
          color: #444444;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .buttonBox {
        display: flex;
        gap: 21px;
        .confirmButton {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 204px;
          height: 52px;
          background-color: #20315b;
          color: white;
          border-radius: 43px;
        }
        .cancelButton {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 204px;
          height: 52px;
          border: 1px solid #000000;
          border-radius: 43px;
          color: black;
        }
      }
    }
  }
  @media ${device.tabMob} {
    padding: 24px;
    border-radius: 24px;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 344px;
    .bucket {
      gap: 24px;
    }
  }
`;

export default PreTest;
